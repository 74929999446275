import React from 'react';
import Cotentpro from '../../Component/Product/Cotentpro';
import './product.css';
const Product = () => {
  return (
    <div>
        <h1 className='pdh1'>InkTank Printers</h1>
        <Cotentpro/>
    </div>
  )
}

export default Product