import logo from './logo.svg';
import './App.css';
import Home from './Pages/home/Home';
import Header from './Common/Header';
import { BrowserRouter as Router , Routes , Route } from 'react-router-dom';
import Main from './Common/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Common/Footer';
import Contact from './Pages/Contact/Contact'
import Adboutus from './Pages/Aboutus/Adboutus';
import Product from './Pages/Product/Product';
import Workforce from './Component/Product/Workforce';
import Surecolour from './Component/Product/Surecolour';
import Cutsheet from './Component/Product/xerox/Cutsheet';
import Monocut from './Component/Product/xerox/Monocut';
import Modal from './Pages/modal/Modal';
import { useState } from 'react';
import Signageprinter from './Component/Product/Signageprinter';
import Sublimation from './Component/Product/Sublimation ';
import Cad from './Component/Product/Cad';
function App() {
    // State to manage the visibility of the modal
    const [isModalOpen, setIsModalOpen] = useState(true);

    // Function to close the modal
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  return (
   <div>
    <Router>
      {/* <Main/> */}
      <Header/>
      
      <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/Contact' element={<Contact/>}/>
          <Route exact path='/about-us' element={<Adboutus/>}/>
          <Route exact path='/Workforce' element={<Workforce/>}/>
          <Route exact path='/Surecolour' element={<Surecolour/>}/>
          <Route exact path='/MonoChromeEcoTank' element={<Product/>}/>
          <Route exact path='/Monocut' element={<Monocut/>}/>
          <Route exact path='/Cutsheet' element={<Cutsheet/>}/>
          <Route exact path='/signageprinter' element={<Signageprinter/>}/>
          <Route exact path='/Sublimation' element={<Sublimation/>}/>
          <Route exact path='/Cad' element={<Cad/>}/>
      </Routes>
      <Footer/>
    </Router>
    {isModalOpen && <Modal onClose={handleCloseModal} />}
  </div>
  );
}

export default App;
