import React from 'react'
import './Contenet.css'
import coimg from './img/WF-C878R_Head-on_690x460 (1).png'
import coimg1 from './img/WorkForce_Enterprise_WF-C21000_Product_03_Left_Angle__91851.jpg'
import coimg2 from './img/29120-productpicture-lores-wf-c879rd3twfc.webp'
import coimg3 from './img/WF-C5790_01-1.jpg'
import coimg4 from './img/18Cli_FPR_01_2-2.jpg'
import coimg5 from './img/19Gra_FDV_01_2-1.jpg'
import coimg6 from './img/34957-productpicture-lores-ix-ix-am-c4000.png'
import { Download } from '@mui/icons-material'
const Workforce = () => {
    return (
        <div className='cpdiv1'>
            <h1 className='pdh1'>Line inject printer</h1>
            <p className='cpp1'>Epson business inkjet printers deliver amazing quality, blazing-fast speeds, exceptional reliability and dependable performance to meet your business needs.</p>

            <div className="card">

                <h1 className='header1'> Epson WorkForce Pro WF-C5890 Wi-Fi Duplex All-in-One Inkjet Printer</h1>
                <div className="cpdiv2 ">
                    <div className="cpdiv3 cardp">
                        <img src={coimg3} alt="" />
                        <div className="cpdiv4">
                            <h5>
                            Built for Reliability and Powerful Productivity
                            </h5>
                            <p>
                            Powered by revolutionary Heat-Free technology, the WorkForce WF-C5890 colour printer features the next generation of inkjet printing technology. This workhorse also includes an innovative Replaceable Ink Pack System to help minimize your interventions—you can print up to 5,000 ISO pages with replacement ink packs1, reducing ink replacement frequency. And you save on printing―up to 25 percent on replacement ink vs. colour laser toner2. With the lowest power consumption in its class3, the WF-C5890 delivers powerful productivity, with a recommended monthly page volume (MPV) of 5,000 pages, print speeds of 25 ISO ppm (black/colour)†, and a fast first page out with virtually no warmup time.</p>                            <ul>
                                <li>Lowest power consumption in its class3</li>
                                <li>Recommended monthly print volume of 5,000 pages</li>
                                <li>25 ISO ppm (black/colour)†; fast first page out</li>
                                <li>4-in-1 with Wireless, Ethernet & PCL4/PS3: Print / Copy / Scan / Fax</li>
                                <li>Large 1,830-sheet paper handling capacity (with three optional trays)</li>
                               
                               
                            </ul>
                        </div>
                    </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
                </div>
                <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/915bf68b63ecd3803101a013ac9af4e50c497303/original?assetDescr=WF%20C5890" className='download'><Download/> Download</a>
            </div>

            <div className="card">

                <h1 className='header1'>Epson WorkForce Pro WF-C579R Duplex All-in-One Inkjet Printer</h1>
                <div className="cpdiv2 ">
                    <div className="cpdiv3 cardp">
                        <img src={coimg4} alt="" />
                        <div className="cpdiv4">
                            <h5>
                            BUILT TO SUIT YOUR EVOLVING BUSINESS NEEDS.
                            </h5>
                            <p>
                            Enjoy low-cost, durable and reliable printing in high volume with the Epson WorkForce WF-C579R business printer. Deliver fast print speeds at 24ipm with a first colour page-out time in just 5.3secs. Reduce the need for replacing consumables with high capacity ink packs that help to lower running costs. With a range of printing and connectivity features, this printer is the ideal solution for workgroups that require hassle-free, low-maintenance and high volume printing.                            </p>
                            <ul>
                                <li>Fast print speed of up to 24ipm</li>
                                <li>Automatic duplex printing and scanning</li>
                                <li>High capacity ink packs</li>
                                <li>Low power consumption</li>
                                <li>Print, scan, copy, fax with ADF</li>
                               
                            </ul>
                        </div>
                    </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
                </div>
                <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/b9104c3387a0b76a7775bf2c36fd8238cd8a1e3d/original?assetDescr=EPSON%20WorkForce%20Pro%20WF-C579R.pdf" className='download'><Download/> Download</a>
            </div>

            <div className="card">

                <h1 className='header1'>WorkForce Pro WF-C878R Multifunction A3+ Color Printer</h1>
                <div className="cpdiv2 ">
                    <div className="cpdiv3 cardp">
                        <img src={coimg} alt="" />
                        <div className="cpdiv4">
                            <h5>
                                COST AND ENERGY EFFICIENCY THAT POWER PERFORMANCE.
                            </h5>
                            <p>
                                The WorkForce Pro RIPS WF-C878R multi-function printers is engineered to blaze through workloads with absolute consistency. High-volume printing turns effortless with speeds of up to 25 ipm^ and a print yield as high as 86,000 pages. Low-cost printing at just 30 Paise‡ (black) and 75 Paise‡ (colour) enables cost-effective printing. These printers also feature Epson’s Heat-Free Technology for a low power consumption and more. Epson’s DURABriteTM pigmentbased inks help deliver razor-sharp, water-resistant prints.
                            </p>
                            <ul>
                                <li>Up to 86,000 Pages (black Ink)</li>
                                <li>Epson Connect</li>
                                <li>25ipm^</li>
                                <li>30 Paise‡ (black) and 75 Paise‡ (colour)</li>
                                <li>Duplex Printing</li>
                                <li>Ethernet</li>
                                <li>Wi-Fi Direct</li>
                                <li>Print Up to A3+</li>
                            </ul>
                        </div>
                    </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
                </div>
                <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/e0a79cfde790e638cf5118a87d5ce0f4a4b0557a/original?assetDescr=Epson%20WF-C878R_C879R" className='download'><Download/> Download</a>
            </div>








            <div className="card">

                <h1 className='header1'>
                    WorkForce Pro WF-C879R Multifunction A3+ Color Printer </h1>
                <div className="cpdiv2rev">

                    <div className="cpdiv3 cardp">
                        <img src={coimg2} alt="" />
                        <div className="cpdiv4">
                            <h5>
                                COST AND ENERGY EFFICIENCY THAT POWER PERFORMANCE.
                            </h5>
                            <p>

                                The WorkForce Pro RIPS WF-C879R multifunction printers are designed for high-volume printing with consistent performance. They offer fast printing speeds of up to 26 ipm^ and can handle large print jobs with a yield of up to 86,000 pages. Printing costs are kept low, at just 30 Paise‡ for black and 75 Paise‡ for color printing, making them cost-effective. These printers utilize Epson’s Heat-Free Technology, resulting in low power consumption. Additionally, Epson’s DURABriteTM pigment-based inks produce sharp and water-resistant prints.
                            </p>

                            <li>Epson Connect
                            </li>
                            <li>

                                Up to 86,000 Pages (black Ink)
                            </li>
                            <li>

                                26ipm^
                            </li>
                            <li>
                                30 Paise‡ (black) and 75 Paise‡ (colour)

                            </li>

                            <li>

                                Duplex Printing

                            </li>
                            <li>

                                Ethernet

                            </li>
                            <li>

                                Wi-Fi Direct

                            </li>
                            <li>

                                Print Up to A3+

                            </li>

                        </div>
                        {/* <Detail/> */}
                    </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
                </div>
                <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/e0a79cfde790e638cf5118a87d5ce0f4a4b0557a/original?assetDescr=Epson%20WF-C878R_C879R" className='download'><Download/> Download</a>
            </div>
           
            <div className="card">
            <h1 className='header1'>WorkForce Enterprise WF-M21000 A3 Monochrome Multifunction Printer</h1>
            <div className="cpdiv2">

                <div className="cpdiv3 cardp">
                    <img src={coimg5} alt="" />
                    <div className="cpdiv4">
                        <h5>
                        Unlock the future of business printing in monochrome.
                        </h5>
                        <p>

                        Experience the prowess of the WF-M21000, part of the new WorkForce Enterprise series in monochrome. Increase your business productivity with print speed of up to 100 pages per minute and maximise performance with the new enhanced finishing options. Powered by Epson PrecisionCore Heat-Free Technology, the WorkForce Enterprise series consumes up to 85% less power than laser printers*, resulting in low running cost. Enjoy greater peace of mind for your business with fewer replacement parts and ultra high yield consumables that reduce impact on the environment.                        </p>

                        <li>4-in-1 Network MFP: Print | Copy | Scan | Fax</li>
                <li>

                ISO Print Speed: 100 ipm (simplex/duplex)1
                </li>
                <li>

                High yield ink cartridges for printing of up to 120,000 pages (black)2
                </li>
                <li>

                New finishing options for stapling, hole-punching and booklet printing
                </li>
                <li>

              1 Year Warranty Or 6000000 Copies
                </li>
               
                    </div>
                    {/* <Detail/> */}
                </div>
                <span className="top"></span>
                <span className="bottom"></span>
                <span className="right"></span>
                <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/fc08ff6f2cb1d1779984b00771adad7c81639dd4/original?assetDescr=Epson%20WF-M21000" className='download'><Download/> Download</a>
            </div>
            <div className="card">
            <h1 className='header1'>WorkForce Enterprise WF-C20600/WF-C20750/WF-C21000</h1>
            <div className="cpdiv2">

                <div className="cpdiv3 cardp">
                    <img src={coimg1} alt="" />
                    <div className="cpdiv4">
                        <h5>
                            THE NEW STANDARD OF EFFICIENCY IN BIG OFFICE PRINTING.
                        </h5>
                        <p>

                            The WorkForce Enterprise WF-C20600 multi-function printers are on the cutting-edge in enterprise-class printing. Speeds of up to 60 ipm^ make high workload handling a breeze. A yield of up to 100,000 prints^ enables seamless printing. Environmental impact isn’t a cause for concern with a power consumption as low as 300 Watts. Plus, these printers are equipped with Epson’s Heat-Free Technology - an innovation that ensures that there is no heat generation while printing. The Epson Print Admin helps control and secure the printing environment. And, features such as wireless printing bring added convenience to the table.
                        </p>

                        {/* <li>Print resolution of 4800 dpi</li>
                <li>

                    Print speed — 32 ppm^ (Black), 32 ppm° (Colour)
                </li>
                <li>

                    ISO print speed -25 ipm° (Black), 25 ipm^ (Colour)
                </li>
                <li>

                    Wireless printing with Epson iPrint and Apple AirPrint
                </li>
                <li>


                    Double-sided printing plus ADF

                </li>
                <li>


                    Secure printing

                </li>
                <li>


                    Smudge and fade-resistant prints with pigment black ink

                </li>
                <li>


                    Double-Pass Auto Duplex Scanning

                </li> */}
                    </div>
                    {/* <Detail/> */}
                </div>
                <span className="top"></span>
                <span className="bottom"></span>
                <span className="right"></span>
                <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/f691b26ec4999b43dfc090f6c7a7f8240d832b7f/original?assetDescr=Epson%20WF_C20600_C20750%20_C21000" className='download'><Download/> Download</a>
            </div>

            <div className="card">
            <h1 className='header1'>WorkForce Enterprise​ AM-C4000/ 5000 / 6000 ​</h1>
            <div className="cpdiv2">

                <div className="cpdiv3 cardp">
                    <img src={coimg6} alt="" style={{width:"200px"}} />
                    <div className="cpdiv4">
                        <h5>
                        More sustainable printing
                        </h5>
                        {/* <p>

                            The WorkForce Enterprise WF-C20600 multi-function printers are on the cutting-edge in enterprise-class printing. Speeds of up to 60 ipm^ make high workload handling a breeze. A yield of up to 100,000 prints^ enables seamless printing. Environmental impact isn’t a cause for concern with a power consumption as low as 300 Watts. Plus, these printers are equipped with Epson’s Heat-Free Technology - an innovation that ensures that there is no heat generation while printing. The Epson Print Admin helps control and secure the printing environment. And, features such as wireless printing bring added convenience to the table.
                        </p> */}

                        <li> High reliability Simple mechanism for environments where downtime is not an option</li>
                <li>

                More sustainable Lower power consumption saves energy thanks to Heat-Free technology
                </li>
                <li>

                Small footprint Amongst the lowest footprint in its class
                </li>
                <li>

                Reduced downtime Simpler, shorter paper path for easier paper jam removal
                </li>
                <li>


                Solution compatibility A wide variety of integrated software solutions

                </li>
                <li>


                All Available in different PPM = 40 / 50 / 60
                </li>
               
                    </div>
                    {/* <Detail/> */}
                </div>
                <span className="top"></span>
                <span className="bottom"></span>
                <span className="right"></span>
                <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/f691b26ec4999b43dfc090f6c7a7f8240d832b7f/original?assetDescr=Epson%20WF_C20600_C20750%20_C21000" className='download'><Download/> Download</a>
            </div>
        </div>
   
       
    )
}

export default Workforce