import React from 'react'
import xerox from '../../Pages/home/image/xerox.png'
import xerox1 from '../../Pages/home/image/kisspng-hewlett-packard-epson-ink-cartridge-printer-logo-5b215a851a3ca1.3227644515289125171075.png'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import './Trust.css'
const Patner = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const navigate = useNavigate();
  return (
    <div className='pamain'>
        <div className='padiv2'> 
       <h1>Partners For</h1> 
        </div>
    <div className='paduv1'>

     <div className="pacard">
        <div className="paimg">
            <img src={xerox1} alt="" width={200} height={100} />
        </div>
        <div className="padetails">
            <p className="pap">
            Epson has an extensive range of Multifunction printers, data and home theatre projectors, as well as POS printers and large format printing solutions.
            </p>
            <div className="padiv3">

            <Button className="pabtn1" onClick={()=>{navigate('Workforce'); scrollToTop();}}>
                View More Product
            </Button>
            </div>
        </div>
     </div>
        
     <div className="pacard">
        <div className="paimg">
            <img src={xerox} alt="" width={200} height={100} />
        </div>
        <div className="padetails">
            <p className="pap">
            Xerox digital printers are the workhorses of today’s office, and they come in a variety of configurations to suit every type of business. Everything from printing, scanning, and copying to faxing and emailing is available.with customizable apps to elevate them.
            </p>
        </div>
        <Button className="pabtn1" onClick={()=>{navigate('cutsheet'); scrollToTop(); }}>
            View More Product
        </Button>
     </div>
    </div>
    </div>
  )
}

export default Patner