// Modal.js
import React, { useState } from 'react';
import './Modal.css';
import pop from '../home/image/pop.jpg'
const Modal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={handleClose}>&times;</span>
        <img src={pop} alt="" />
      </div>
    </div>
  );
};

export default Modal;
