import React from 'react'
import './Contenet.css'
import coimg from './img/S40670_550px_X_310px.png'
import coimg1 from './img/Hil_2L_STD01_2.jpg'
import coimg2 from './img/4-Leggero-TX_Front_Home_TX_shadow_No.jpg'
import coimg3 from './img/Sam_2L_STD01_2.jpg'
import { Download } from '@mui/icons-material'

const Signageprinter = () => {
  return (
    <div>
       <div className='cpdiv1'>
            <h1 className='pdh1'>Signage Printers</h1>
            <p className='cpp1'>Epson offers a variety of signage printers, like the SureColor series, for businesses needing high-quality prints for signs, banners, and posters. These printers boast advanced technologies for precise printing and support various media types. Epson's SureColor printers are known for their durability and reliability, making them popular choices for indoor and outdoor signage.</p>


        <div className="card">

                        <h1 className='header1'>Epson SureColor SC-S40670 Eco-Solvent Signage Printer</h1>
            <div className="cpdiv2">
                <div className="cpdiv3 cardp">
                    <img src={coimg} alt="" />
                    <div className="cpdiv4">
                        <h5>
                        High Quality at Affordable Cost of Ownership
                        </h5>
                        <p>
                        The Epson SureColorTM SC-S40670 is designed to deliver exceptional quality at low ownership cost for all types of indoor and outdoor commercial printing applications including posters, signs, banners, backlit panels, interior decorations, exhibition panels and more.                        </p>
                        <ul>
                            <li>Exceptional quality at low cost of ownership</li>
                            <li>Up to 64-inch width and 1.0 mm thickness media</li>
                            <li>Print up to 58.4 m2/h</li>
                            <li>Epson UltraChrome GS3 ink Technology</li>
                            <li>2-years Unlimited warranty</li>

                        </ul>
                    </div>
                </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/c03f4f4c9402f5f5ec3ead363d7bf6a5827cde17/original?assetDescr=SC-S40670S%20-%2060670%20Brochure" className='download'><Download/> Download</a>
        </div>







        <div className="card">

                        <h1 className='header1'>
                        Epson SureColor SC-S60670/SC-S60670L Eco-Solvent Signage Printer </h1>
            <div className="cpdiv2rev">

                <div className="cpdiv3 cardp">
                    <img src={coimg1} alt="" />
                    <div className="cpdiv4">
                        <h5>
                        Print with confidence even with the most demanding jobs
                        </h5>
                        <p>
                        The Epson SureColorTM SC-S60670L offers the widest colour gamut to meet most demanding jobs for a wide variety of indoor and commercial applications with the 4-colour UltraChromeTM Epson UltraChrome GS3 ink.

Epson UltraChromeTM GS3 ink Technology - Greenguard Gold certified for low chemical emissions


                        </p>

                        <li>
                        Ink Bulk System for higher print volume run

                        </li>
                        <li>

                        Wide colour gamut to meet most demanding jobs with 4-colour configuration
                        </li>
                        <li>

                        Up to 162.6 cm (64-inch) width and 1.0 mm thickness media
                        </li>
                        <li>
                        Epson UltraChromeTM GS3 ink Technology with Red - Greenguard Gold certified for low chemical emissions
                        </li>

                        <li>

                        2-years Unlimited warranty

                        </li>
                       
                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/d218244e479180edd455c78a8fca7fd41ef4b8bc/original?assetDescr=SC-S60670L_S80670L" className='download'><Download/> Download</a>
        </div>
<div className="card">

                        <h1 className='header1'>Epson SureColor SC-S80670/SC-S80670L Eco-Solvent Signage Printer</h1>
            <div className="cpdiv2">

                <div className="cpdiv3 cardp">
                    <img src={coimg3} alt="" />
                    <div className="cpdiv4">
                        <h5>
                        Print with confidence even with the most demanding jobs
                        </h5>
                        <p>

                        The Epson SureColorTM SC-S80670L offers the widest colour gamut to meet most demanding jobs for a wide variety of indoor and commercial applications with the 10-colour UltraChromeTM Epson UltraChrome GS3 - with red ink and option of white or metallic silver colours.

Wide colour gamut to meet most demanding jobs with 10-colour configuration.                         </p>

                        <li>Ink Bulk System for higher print volume run</li>
                        <li>

                        Wide colour gamut to meet most demanding jobs with 10-colour configuration
                        </li>
                        <li>

                        Up to 162.6 cm (64-inch) width and 1.0 mm thickness media
                        </li>
                        <li>

                        Epson UltraChromeTM GS3 ink Technology with Red - Greenguard Gold certified for low chemical emissions
                        </li>
                        <li>


                        2-years Unlimited warranty
 

                        </li>

                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/d218244e479180edd455c78a8fca7fd41ef4b8bc/original?assetDescr=SC-S60670L_S80670L" className='download'><Download/> Download</a>
</div>
           

        </div>
    </div>
  )
}

export default Signageprinter
