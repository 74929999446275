import React from 'react'
import './Contenet.css'
import coimg from '../../Pages/home/image/19Lafin2_24_STD_01_C_a_2.jpg'
import coimg1 from './img/P8570D_1_Front_44D_P_690x460.jpg'
import coimg2 from './img/SC-SC-P6430E_01.jpg'
import coimg3 from './img/Epic-M_1_1_foldedPane.jpg'
import { Download } from '@mui/icons-material'

const Surecolour = () => {
    return (
        <div className='cpdiv1'>
            <h1 className='pdh1'>Photo Printers</h1>
            <p className='cpp1'>Epson's large format printers, like the SureColor series, are designed for businesses needing high-quality prints on big media. They're equipped with advanced technology for precision and support various media types. These printers are known for their durability and reliability, making them popular for indoor and outdoor signage, banners, and posters.</p>


        <div className="card">

                        <h1 className='header1'>Epson SureColor SC-P7530/P9530 Photo Graphic Production Printer</h1>
            <div className="cpdiv2">
                <div className="cpdiv3 cardp">
                    <img src={coimg} alt="" />
                    <div className="cpdiv4">
                        <h5>
                            Impeccable Photo Quality. High-Accuracy Color Reproduction.
                        </h5>
                        <p>
                            Add a touch of extraordinary performance to your photo printing with Epson SureColorTM 44-inch(112cm) SC-P9530 large format photo printers. Unparalleled wide colour gamut print quality with UltraChrome PRO12 ink perfectly paired with Epson's PrecisionCoreTM MicroTFPTM printhead, to deliver high quality prints at outsranding speeds.
                        </p>
                        <ul>
                            <li>44-inch(112cm) high production printer</li>
                            <li>New PrecisionCoreTM MicroTFP printhead utilising 8000 nozzles, for print speeds up to 17.5 m2/h</li>
                            <li>Epson UltraChromeTM PRO archival ink technology with four levels of grey</li>
                            <li>New media feeding system to upkeep high print quality and reduce downtime</li>
                            <li>Ideal for high-volume commercial printing</li>

                        </ul>
                    </div>
                </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
            </div>
            <a href="http://mediaserver.goepson.com/ImConvServlet/imconv/82159545798cadbae0805fee25fc0ee846a17c88/original?assetDescr=SURECOLOR%20SC-P7530%20SC-P9530" className='download'><Download/> Download</a>
        </div>







        <div className="card">

                        <h1 className='header1'>
                        Epson SureColor SC-P6530E Large Format Professional Photo Printer</h1>
            <div className="cpdiv2rev">

                <div className="cpdiv3 cardp">
                    <img src={coimg2} alt="" />
                    <div className="cpdiv4">
                        <h5>
                        AMPLIFY YOUR IDEAS WITH TOP CLASS PRINT QUALITY
                        </h5>
                        <p>

                        Transform your ideas into striking true-to-life prints with Epson’s P-series printers. Nothing beats seeing life injected into your ideas, turning them into picturesque prints in vivid and vibrant colours with top-class accuracy. Equipped with the Epson’s proprietary PrecisionCore MicroTFP printheads, the Epson SureColor SC-P6530E with 24" wide-format media maximises productivity and increases efficiency. Its 6-colour ink set delivers exceptionally wide colour gamut and high black density, producing photographic prints that are of gallery standards.
                        </p>

                        <li>
                        The new addition of grey ink ensures smooth gradations and neutral greyscale
     in every photo
                        </li>
                        <li>

                        Printer's flat top design allows for easy placement in narrow spaces, giving
     businesses the flexibility in installation
                        </li>
                        <li>

                        Epson Cloud Solution PORT enables remote monitoring of printer fleet across
     different sites easy and hassle-free, whether it's on a mobile or PC device
                        </li>
                        <li>
                        USB Direct Print without the need for a PC

                        </li>

                       

                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
            </div>
            <a href="https://pro.epson.asia/brochure/SC-P6530-P8530" className='download'><Download/> Download</a>
        </div>
        <div className="card">

                        <h1 className='header1'>
                            Epson SureColor P8530D 1118 mm (44-Inch) Wide-Format Dual Roll Printer </h1>
            <div className="cpdiv2rev">

                <div className="cpdiv3 cardp">
                    <img src={coimg1} alt="" />
                    <div className="cpdiv4">
                        <h5>
                            Power Up Productivity.
                        </h5>
                        <p>

                            Imagine a wide-format production photo printer engineered to achieve unbelievable quality and speed with innovative, automatic dual roll switching.

                            We’ve rethought photographic production printing from the ground up to deliver a high-performance solution built to enhance the way you work. Introducing the SureColor® P8530D — a new evolution in photographic production printing, only from Epson.
                        </p>

                        <li>
                            Print resolution of 2^00 dpi
                        </li>
                        <li>

                            PrecisionCore’" Micro TFP” printhead
                        </li>
                        <li>

                            with Variable-sized Droplet +echnolopy
                        </li>
                        <li>
                            Fade-resistant colour prints up to 200 years , black prints up to 400 years^

                        </li>

                        <li>

                            Wide colour gamut with UltraChrome’" PRO12

                        </li>
                        <li>

                            10 9 cm (4.3' ) optical touch colour LCD

                        </li>
                        <li>

                            Easy media handling

                        </li>
                        <li>

                            Built-in Ethernet

                        </li>

                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
                    <span className="bottom"></span>
                    <span className="right"></span>
                    <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/6622a86e2e9b7f71e913624d6fccb42e04349500/original?assetDescr=Epson%20P8530D" className='download'><Download/> Download</a>
        </div>

        </div>
    )
}

export default Surecolour