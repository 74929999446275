

import React from 'react'
import './Contenet.css'
import coimg from './img/SC-T3130M-(1).jpg'
import coimg1 from './img/SC-T3130N.jpg'
import coimg2 from './img/T7770D_1_Front_44D_T_690x460.jpg'
import coimg3 from './img/4-Leggero-TX_Front_Home_TX_shadow_No.jpg'
import coimg5 from './img/SC-T3730D_DE-(1).jpg'
import coimg6 from './img/Epic-M_1_1_foldedPane (2).jpg'
import coimg7 from './img/epson-sc-t5630dm-500x500.webp'

import { Download } from '@mui/icons-material'


const Cad = () => {
    return (
        <div>
            <div className='cpdiv1'>
                <h1 className='pdh1'>CAD PRINTER</h1>
                <p className='cpp1'>Epson offers a variety of signage printers, like the SureColor series, for businesses needing high-quality prints for signs, banners, and posters. These printers boast advanced technologies for precise printing and support various media types. Epson's SureColor printers are known for their durability and reliability, making them popular choices for indoor and outdoor signage.</p>



                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T3130M 24” Multi-Function Technical Printer</h1>
                    <div className="cpdiv2rev">

                        <div className="cpdiv3 cardp">
                            <img src={coimg} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                    Go Big on Exceptional Accuracy.
                                </h5>
                                <p>

                                    Take your ideas to a bigger scale with greater performance and precision with the Epson SureColorTM SC-T3130M printers. With PrecisionCoreTM printheads and
                                    UltraChromeTM XD2 ink to bring about fast printing speeds, enjoy a seamless work process and top-class accuracy in every print. Showcase your ideas and plans in a
                                    big way, right down the finest detail.</p>
                                <li> Impressive accuracy and sharpness at outstanding speeds</li>
                                <li>

                                    Compact and user-friendly design                     </li>
                                <li>
                                    Multi-function capabilities with in-built scanner
                                </li>
                                <li>
                                    High productivity with seamless scan-to-print function

                                </li>
                                <li>
                                    Print width of up to 60.96 cm (24 inch)
                                </li>

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/4bf2bc825f3728b470444ca2813e95c74fba2268/original?assetDescr=Epson%20SC%20T3130M%20T5130M" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T3130N Wireless Technical Printer</h1>
                    <div className="cpdiv2rev">

                        <div className="cpdiv3 cardp">
                            <img src={coimg1} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                    High-speed, easy-to-use, compact 24" desktop inkjet printer.
                                </h5>
                                <p>

                                    Engineered to enhance the way you work, the 24" SureColor T3130N wide-format wireless printer offers fast, precise and reliable printing with a compact, clean desktop design. Ideal for printing blueprints, line drawings and posters up to 24", it produces accurate, A1/D-size prints in 34 seconds1. Whether you’re printing from a roll or the Auto Sheet Feeder, the 4.3" color touchscreen makes tasks simple and intuitive. Next-generation high-capacity UltraChrome®XD2 pigment inks deliver durable, vibrant prints. And, with integrated wireless and Wi-Fi Direct® connectivity2, you can easily print from tablets and smartphones3, from virtually anywhere in the office.

                                </p>


                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/8a4ae64505c2f94d84c8948441bdf91d582e6399/original?assetDescr=SC-T3435%20-%20T5435" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T3130X Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg3} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                    Distinctive Quality Meets Endless Possibilities with Epson’s first CISS entry level CAD printer
                                </h5>
                                <p>

                                    Take your ideas to a bigger scale with greater performance and precision with the Epson SureColor SC-T3130X printer. With PrecisionCore printheads, and UltraChrome XD2 ink to bring fast printing speeds, seamless work process and top-class accuracy in every print. Showcase your ideas and plans in a big way right down the finest detail.</p>

                                <li>Epson’s first technical printer with CISS ink tank for continuous ink supply</li>
                                <li>

                                    61cm(24-inch) Technical Printer using UltraChrome XD2 ink for high quality prints
                                </li>
                                <li>

                                    Compact, user-friendly and anti-dust design
                                </li>
                                <li>

                                    10.9cm(4.3”) touch panel for easier usability
                                </li>
                                <li>


                                    Wireless connectivity with Wi-Fi, Wi-Fi Direct and AirPrint*

                                </li>

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/f542f6634e436b1a7482e3f8a5310be2fe55fa40/original?assetDescr=Surecolor%20SC-T5430M" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T3730D 24" Dual Roll Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg5} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                    SUSTAINABLE INNOVATION WITH EXCELLENT SCALABILITY.                                </h5>
                                <p>

                                    Be ready to amplify your ideas and showcase them in spectacular precision with Epson’s new SureColor technical printer series. Equipped with PrecisionCore MicroTFP printheads for precise printing and vivid colour-accurate prints using the UltraChrome XD3 inks. On top of versatile printing capabilities, the printers come equipped with user-friendly functions and secured management for businesses to enjoy a seamless printing process. Get ready to be mesmerised by the exceptional line printing accuracy and top-class efficiency.</p>                                <li>Epson’s first technical printer with CISS ink tank for continuous ink supply</li>
                                <li>

                                    Outstanding print speeds for CAD documents of up to 16s (A1 prints) and 28s (A0 prints).                                </li>
                                <li>

                                    Improved image quality with 6 colour UltraChrome XD3 inks.
                                </li>
                                <li>

                                    Higher capacity (350ml/700ml) ink set available

                                </li>
                                <li>


                                    Enhanced security with self-encrypting SSD and print authentication

                                </li>
                                <li>


                                    Full frontal operation with sleek chassis design
                                </li>
                                <li>


                                    Dual roll for increased non-stop productivity
                                </li>

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/f542f6634e436b1a7482e3f8a5310be2fe55fa40/original?assetDescr=Surecolor%20SC-T5430M" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T5130M 36” Multi-Function Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                    Go Big on Possibilities and Precision.</h5>
                                <p>

                                    Take your ideas to a bigger scale with greater performance and precision with the Epson SureColor SC-T5130M printers. With PrecisionCore printheads and UltraChrome XD2 ink to bring about fast printing speeds, enjoy a seamless work process and top-class accuracy in every print. Showcase your ideas and plans in a big way right down the finest detail.  </p>
                                <li>

                                    Impressive accurate and sharpness at outstanding speeds.                                </li>
                                <li>

                                Compact and user-friendly design
                                </li>
                                <li>

                                Multi-function capabilities with in-built scanner 
                                </li>
                                <li>


                                High productivity with seamless scan-to-print function
                                </li>
                                <li>


                                Print width of up to 36”
                                </li>
                              
                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://pro.epson.asia/brochure/SC-T3130M_5130M_Updated" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T5430M Multifunction Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg6} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                Take your plans to a bigger scale                         </h5>
                                <p>

                                The T-series printers heighten your ideas by showcasing them in a big way with its stand-out performance, impeccable precision and exceptional engineering. Engineered for precision and reliability, the printer comes with the PrecisionCore printheads and UltraChrome XD2 ink to help businesses go big on their plans.</p> 
<li>

36-inch Large Format multifunction technical printer with scanning capability                               </li>
                                <li>

                                Top class line accuracy
                                </li>
                                <li>

                                Durable and vibrant printouts with Epson all-pigment UltraChrome XD Ink
                                </li>
                                <li>


                                Outstanding speeds of up to 2 A1 prints per minute
                                </li>
                               

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://pro.epson.asia/brochure/T5430M" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T5730D 36" Dual Roll Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg5} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                SUSTAINABLE INNOVATION WITH EXCELLENT SCALABILITY
                            </h5>
                                <p>

                                Be ready to amplify your ideas and showcase them in spectacular precision with Epson’s new SureColor technical printer series. Equipped with PrecisionCore MicroTFP printheads for precise printing and vivid colour-accurate prints using the UltraChrome XD3 inks. On top of versatile printing capabilities, the printers come equipped with user-friendly functions and secured management for businesses to enjoy a seamless printing process. Get ready to be mesmerised by the exceptional line printing accuracy and top-class efficiency.</p>
                                        <li>

                                        Outstanding print speeds for CAD documents of up to 16s (A1 prints) and 28s (A0 prints).                                </li>
                                <li>

                                Improved image quality with 6 colour UltraChrome XD3 inks.
                                </li>
                                <li>

                                    HHigher capacity (110ml/350ml/700ml) ink set available

                                </li>
                                <li>


                                Enhanced security with self-encrypting SSD and print authentication
                                </li>
                                <li>


                                    Full frontal operation with sleek chassis design
                                </li>
                                <li>


                                    Dual roll for increased non-stop productivity
                                </li>

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/42d8964c1f2c877e486e31da6aa4245339b83398/original?assetDescr=Tx370%20Range%20Bro" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T5730DM 36" Dual Roll Multifunction Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg5} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                PRECISION PRINTING WITH ULTRA PRODUCTIVE SCANNING SOLUTION                                </h5>
                                <p>

                                Be ready to amplify your ideas and showcase them in spectacular precision with Epson’s SureColor all-in-one multi-function technical printers SC-T5730DM and SC-T7730DM. Equipped with PrecisionCore MicroTFP printheads for precise printing and vivid colour-accurate prints using the UltraChrome XD3 inks, it brings top-class accuracy and efficiency in every print. On top of versatile printing capabilities, the printers come equipped with user-friendly functions and secured management for businesses to enjoy a seamless printing process. Get ready to be mesmerised by the exceptional line printing accuracy and top-class efficiency.</p>
                               
                                <li>

                                    Outstanding print speeds for CAD documents of up to 16s (A1 prints) and 28s (A0 prints).                                </li>
                                <li>

                                    Improved image quality with 6 colour UltraChrome XD3 inks.
                                </li>
                                <li>

                                Multifunction printer with copying and scanning for hassle free operation

                                </li>
                                <li>


                                    Enhanced security with self-encrypting SSD and print authentication

                                </li>
                                <li>


                                    Full frontal operation with sleek chassis design
                                </li>
                                <li>


                                    Dual roll for increased non-stop productivity
                                </li>

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>EPSON SC-T5630DM Colour Printer 36" (a0 Size) , Print Resolution: 1200 X 1200dpi, Inkjet Technology</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg7} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                Product Description                              </h5>
                                <p>

                                36 inches (A0+), printing, copying, scanning, meeting the needs of daily drawing applications
Brand-new 2.64-inch micro-piezoelectric print head, it only takes 17s to output an A1 format line drawing *1
Dual paper shaft configuration, automatic paper loading, automatic switching between two shafts, flexible collocation, efficient production
6-color Epson "Live Color DS6" dye ink, 1.6L large-capacity ink bag, high quality and high efficiency, wonderful reproduction
Self-owned brand built-in scanner, scanning speed up to 10 inches per second, support scanning while printing
Efficient blueprint output, supporting "one-click to blue", "blueprint red stamp"
Support Epson Cloud Solution PORT   </p>                            
                               
                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T7730D 1118mm (44") Dual Roll Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg5} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                SECURED RELIABILITY WITH IMMACULATE PRECISION                                </h5>
                                <p>

                                Be ready to amplify your ideas and showcase them in spectacular precision with Epson’s new SureColor technical printer which comes equipped with PrecisionCore MicroTFP printheads for precise printing and vivid colour-accurate prints using the UltraChrome XD3 inks. On top of its versatile printing capabilities, the printer comes equipped with user-friendly functions and secured management for businesses to enjoy a seamless printing process. Get ready to be mesmerised by the exceptional line printing accuracy and top-class efficiency.
                                </p>
                                                              
                                  <li>

                                    Outstanding print speeds for CAD documents of up to 16s (A1 prints) and 28s (A0 prints).                                </li>
                                <li>

                                    Improved image quality with 6 colour UltraChrome XD3 inks.
                                </li>
                                <li>

                                Ultra-high capacity 1600ml ink pack for high production users

                                </li>
                                <li>


                                    Enhanced security with self-encrypting SSD and print authentication

                                </li>
                                <li>


                                    Full frontal operation with sleek chassis design
                                </li>
                                <li>


                                    Dual roll for increased non-stop productivity
                                </li>

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="" className='download'><Download /> Download</a>
                </div>
                <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T7730DM 1118mm (44") Dual Roll Multifunction Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg5} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                PRECISION PRINTING WITH ULTRA PRODUCTIVE SCANNING SOLUTION                                </h5>
                                <p>

                                Be ready to amplify your ideas and showcase them in spectacular precision with Epson’s SureColor all-in-one multi-function technical printers SC-T5730DM and SC-T7730DM. Equipped with PrecisionCore MicroTFP printheads for precise printing and vivid colour-accurate prints using the UltraChrome XD3 inks, it brings top-class accuracy and efficiency in every print. On top of versatile printing capabilities, the printers come equipped with user-friendly functions and secured management for businesses to enjoy a seamless printing process. Get ready to be mesmerised by the exceptional line printing accuracy and top-class efficiency.</p>

                                <li>

                                    Outstanding print speeds for CAD documents of up to 16s (A1 prints) and 28s (A0 prints).                                </li>
                                <li>

                                    Improved image quality with 6 colour UltraChrome XD3 inks.
                                </li>
                                <li>

                                 Multifunction printer with copying and scanning for hassle free operation
                                </li>
                                <li>


                                    Enhanced security with self-encrypting SSD and print authentication

                                </li>
                                <li>


                                    Full frontal operation with sleek chassis design
                                </li>
                                <li>


                                    Dual roll for increased non-stop productivity
                                </li>

                            </div>
                            {/* <Detail/> */}
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/f542f6634e436b1a7482e3f8a5310be2fe55fa40/original?assetDescr=Surecolor%20SC-T5430M" className='download'><Download /> Download</a>
                </div>
                {/* <div className="card">

                    <h1 className='header1'>Epson SureColor SC-T3730D 24" Dual Roll Technical Printer</h1>
                    <div className="cpdiv2">

                        <div className="cpdiv3 cardp">
                            <img src={coimg5} alt="" />
                            <div className="cpdiv4">
                                <h5>
                                    SUSTAINABLE INNOVATION WITH EXCELLENT SCALABILITY.                                </h5>
                                <p>

                                    Be ready to amplify your ideas and showcase them in spectacular precision with Epson’s new SureColor technical printer series. Equipped with PrecisionCore MicroTFP printheads for precise printing and vivid colour-accurate prints using the UltraChrome XD3 inks. On top of versatile printing capabilities, the printers come equipped with user-friendly functions and secured management for businesses to enjoy a seamless printing process. Get ready to be mesmerised by the exceptional line printing accuracy and top-class efficiency.</p>                                <li>Epson’s first technical printer with CISS ink tank for continuous ink supply</li>
                                <li>

                                    Outstanding print speeds for CAD documents of up to 16s (A1 prints) and 28s (A0 prints).                                </li>
                                <li>

                                    Improved image quality with 6 colour UltraChrome XD3 inks.
                                </li>
                                <li>

                                    Higher capacity (350ml/700ml) ink set available

                                </li>
                                <li>


                                    Enhanced security with self-encrypting SSD and print authentication

                                </li>
                                <li>


                                    Full frontal operation with sleek chassis design
                                </li>
                                <li>


                                    Dual roll for increased non-stop productivity
                                </li>

                            </div>
                        </div>
                        <span className="top"></span>
                        <span className="bottom"></span>
                        <span className="right"></span>
                        <span className="left"></span>
                    </div>
                    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/f542f6634e436b1a7482e3f8a5310be2fe55fa40/original?assetDescr=Surecolor%20SC-T5430M" className='download'><Download /> Download</a>
                </div> */}


            </div>
        </div>
    )
}

export default Cad
