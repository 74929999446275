import React from 'react'
import './Trust.css'
import hand from '../../Pages/home/image/handshake (1).png'
import hand1 from '../../Pages/home/image/product.png'
import hand2 from '../../Pages/home/image/inspirational.png'
import hand3 from '../../Pages/home/image/support.png'

const Trust = () => {
  return (
    <div className='card1'>
        <div className="card2">
            <div className='tdiv1'>
                <img src={hand} alt="" />
            </div>
            <div className="tdiv2">
                <h4>100% Trust Brand</h4>
                <p>Your Ultimate Destination for Printing & Finishing Machinery Solutions.</p>
            </div>
        </div>
        <div className="card2">
            <div className='tdiv1'>
                <img src={hand1} alt="" />
            </div>
            <div className="tdiv2">
                <h4>
Range of Product
</h4>
                <p>Different Range Of Products to meet all of your printing requirements</p>
            </div>
        </div>
        <div className="card2">
            <div className='tdiv1'>
                <img src={hand2} alt="" />
            </div>
            <div className="tdiv2">
                <h4>
With Expert Team</h4>
                <p>Working to eliminate the unnecessary steps and to automate the process.</p>
            </div>
        </div>
        <div className="card2">
            <div className='tdiv1'>
                <img src={hand3} alt="" />
            </div>
            <div className="tdiv2">
                <h4>
                Complete Support
</h4>
                <p>We have always made it a priority to satisfy our customers by providing 24/7 Support.</p>
            </div>
        </div>

    </div>
  )
}

export default Trust