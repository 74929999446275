import React from 'react'
import './Trust.css'
const Welcome = () => {
  return (
    <div className='wdiv2'>
        <h1 className='wdiv1'>WELCOME TO IMAGE SOLUTION</h1>
        <div className='wp1'>
        Since 2019, Image Solution has been a trusted name in printing gear. We work closely with big names like Epson and Xerox, serving over 200 happy clients in Pune and Rest of Maharashtra Our gear is not just affordable; it's top-notch quality, designed to keep you satisfied for the long haul. We're all about making printing easy and automatic for you in the future.
        </div>

    </div>
  )
}

export default Welcome