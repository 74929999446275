import React from 'react'
import { Magnifier } from 'react-image-magnify';
import coimg from './img/M15180 (1) (1).jpg'
import coimg1 from './img/M15180 (1).jpg'
import coimg2 from './img/L15150_19Cah_FDL_Black_01_2 (2).jpg'
import './Contenet.css'
import Detail from './Detail'
import { Download } from '@mui/icons-material';
const Cotentpro = () => {
    return (
        <div className='cpdiv1'>
            <p className='cpp1'>Epson InkTank printers are designed for cost-effective printing with refillable ink tanks, reducing the need for frequent cartridge replacements. They offer high-quality prints and are suitable for both home and office use.</p>
                <div className="cpdiv2">
    <div className="card">

        <h1  className='header1'>Epson EcoTank M15140 A3 Wi-Fi Duplex All-in-One Ink Tank Printer</h1>
      <div className="cpdiv3 cardp">
        <img src={coimg} alt="" />
        <div className="cpdiv4">
          <h5>
            TURNING A NEW PAGE IN LOW-COST, HIGH-PERFORMANCE A3+ MONOCHROME PRINTING
          </h5>
          <p>
            This all-in-one monochrome EcoTank printer is perfect for small offices and businesses. It handles A3+ tasks effortlessly and has a low cost per page. You can tackle A3+ jobs quickly with its fast print and scan speeds, and it comes with two 250-sheet A3 front trays, a 50-sheet A3 rear feed, and a 50-sheet A3 automatic document feeder. Plus, you have the flexibility to print from your mobile device, connect via Ethernet, and control it easily with a 6.8cm LCD touchscreen.
          </p>
          <ul>
            <li>Fast print speed of up to 25.0 ipm(A4,Simplex)</li>
            <li>Print resolution 4800dpi</li>
            <li>Automatic duplex printing</li>
            <li>Wi-Fi, Wi-Fi Direct, Ethernet</li>
            <li><b>Warranty of 1 year or 350000 prints</b></li>
          </ul>
        </div>
      </div>
        <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
    <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/e329d7dafbdfc916aeda73ed29313dfa40e26e35/original?assetDescr=Epson%20EcoTank%20M15140" className='download'><Download/>Download</a>
    </div>
    </div>


<div className="card">

                    <h1 className='header1'>Epson EcoTank Monochrome M15180 A3 Wi-Fi Duplex Multi-Function Ink Tank Printer</h1>
            <div className="cpdiv2rev">

                <div className="cpdiv3 cardp">
                    <div className="cpnew">

                   
                <img src={coimg} alt="" />
                    </div>
                    <div className="cpnew1">

                    <div className="cpdiv4">
                        <h5>
                            FLEXIBILITY FOR BETTER BUSINESS PRINT MANAGEMENT
                        </h5>
                        <p>

                            Epson's EcoTank M15180 monochrome printer offers complete flexibility and customization for your business's printing needs. It's equipped with Heat-Free Technology, boosting productivity with its ultra-high page yield and fast printing speeds of up to 25 pages per minute (for single-sided printing). The printouts are sharp, crisp, and water-resistant, thanks to our pigment ink designed for business use. You can enhance security by authenticating device access and implementing printing controls to lower printing costs.
                        </p>

                        <li>Print resolution of 4800 dpi
                        </li>
                        <li>

                            High print speed of 32 ppm
                        </li>
                        <li>

                            ISO print speed of 25 ipm
                        </li>
                        <li>

                            Hi-speed USB connectivity

                        </li>
                        <li>

                            Supports Epson Print Admin


                        </li>
                        <li>

                            Wireless printing with Epson iPrint and Apple AirPrint™™ 9


                        </li>
                        <li>

                            Double-Pass Auto Duplex Scanning


                        </li>
                        <li>

                            <b>
                                Warranty of 3 year or 350000 prints
                            </b>
                        </li>
                    </div>
                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/47acb8e971fee75acbe1e7f7c5a38683d02f0de4/original?assetDescr=EcoTank%20A3%20biz%20printer%20-%20M15180%20copy." className='download'><Download/>Download</a>
</div>
<div className="card">

                    <h1 className='header1'>Epson EcoTank L15150 A3 Wi-Fi Duplex All-in-One Ink Tank Printer</h1>
            <div className="cpdiv2">

                <div className="cpdiv3 cardp">
                <img src={coimg2} alt="" />
                    <div className="cpdiv4">
                        <h5>
                            UNCOMPROMISING A3 PRINT QUALITY AT UNBEATABLE PRICES.
                        </h5>
                        <p>

                            Epson EcoTank L15150: a cost-saving A3 printer designed to elevate your business operations. With an ultra-high page yield of up to 7,500 pages in black and 6,000 pages in color, it ensures long-lasting efficiency. The new EcoTank pigment ink, DURABrite ET INK, delivers sharp, clear, and water-resistant prints, even in barcode mode. Thanks to Epson Heat-Free Technology, enjoy high-speed printing while conserving power. It's your solution for maximizing productivity and minimizing costs, right down to the smallest details.
                        </p>

                        <li>Print resolution of 4800 dpi</li>
                        <li>

                            Print speed — 32 ppm^ (Black), 22 ppm° (Colour)
                        </li>
                        <li>

                            ISO print speed — up to 25 ipm^ (Black), 12 ipm^ (Colour)
                        </li>
                        <li>

                            Prints up to A3+ sizes
                        </li>
                        <li>


                            Double-Pass Auto Duplex Scanning

                        </li>
                        <li>

                            <b>
                                Warranty of 1 year or 200000 prints
                            </b>
                        </li>
                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/6997b92319bc73b582289798fe21522b6bf5b0e4/original?assetDescr=L15150" className='download'><Download/>Download</a>
</div>



<div className="card">

                    <h1 className='header1'>
                    Epson EcoTank L15160 A3 Wi-Fi Duplex All-in-One Ink Tank Printer </h1>
            <div className="cpdiv2rev">

                <div className="cpdiv3 cardp">
                <img src={coimg2} alt="" />
                    <div className="cpdiv4">
                        <h5>
                            THE IDEAL CHOICE FOR FAST AND HIGH-VOLUME BUSINESS QUALITY A3 COLOUR PRINTING
                        </h5>
                        <p>

                            With your business in mind, Epson EcoTank L15160 is built to ensure lower costs for duplex printing and scanning, up to A3 size. Using the latest pigment ink, DURABrite ET INK gives you sharp, clear and durable prints that are water-resistant. Designed to be compact, L15160 can fit into your office easily with its small footprint. When it comes to delivering quality and cost savings, we go right down to the details.
                        </p>

                        <li>Print resolution of 4800 •dpi
                        </li>
                        <li>

                            Print speed — 32 ppm^ (Black and Colour)
                        </li>
                        <li>

                            ISO print speed — up to 25 ipm^ (Black and Colour)
                        </li>
                        <li>

                            Prints up to A3+ sizes

                        </li>

                        <li>

                            Double-Pass Auto Duplex Scanning


                        </li>
                        <li>

<b>
    Warranty of 1 year or 200000 prints
</b>
</li>

                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
            </div>
            <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/4fe8756f7d26dc61365949face933b34e554ec33/original?assetDescr=L15160.pdf" className='download'><Download/> Download</a>
</div>
<div className="card">

                    <h1 className='header1'>Epson EcoTank L15180 A3 Wi-Fi Duplex Multi-Function Ink Tank Printer</h1>
            <div className="cpdiv2">

                <div className="cpdiv3 cardp">
                <img src={coimg2} alt="" />
                    <div className="cpdiv4">
                        <h5>
                            UNCOMPROMISING A3 PRINT QUALITY AT UNBEATABLE PRICES.
                        </h5>
                        <p>

                            Epson EcoTank L15150: a cost-saving A3 printer designed to elevate your business operations. With an ultra-high page yield of up to 7,500 pages in black and 6,000 pages in color, it ensures long-lasting efficiency. The new EcoTank pigment ink, DURABrite ET INK, delivers sharp, clear, and water-resistant prints, even in barcode mode. Thanks to Epson Heat-Free Technology, enjoy high-speed printing while conserving power. It's your solution for maximizing productivity and minimizing costs, right down to the smallest details.
                        </p>

                        <li>Print resolution of 4800 dpi</li>
                        <li>

                            Print speed — 32 ppm^ (Black), 32 ppm° (Colour)
                        </li>
                        <li>

                            ISO print speed -25 ipm° (Black), 25 ipm^ (Colour)
                        </li>
                        <li>

                            Wireless printing with Epson iPrint and Apple AirPrint
                        </li>
                        <li>


                            Double-sided printing plus ADF

                        </li>
                        <li>


                            Secure printing

                        </li>
                        <li>


                            Smudge and fade-resistant prints with pigment black ink

                        </li>
                        <li>


                            Double-Pass Auto Duplex Scanning

                        </li>
                        <li>

<b>
    Warranty of 3 year or 200000 prints
</b>
</li>
                    </div>
                    {/* <Detail/> */}
                </div>
                    <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
            </div>
                <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/988e84b4e013e34c3d92d4ffc4f83bc532d5f66b/original?assetDescr=L15180" className='download'><Download/>Download</a>
</div>

        </div>
    )
}

export default Cotentpro