import React, { useState } from 'react'
import  { Link, useNavigate, useNavigation } from "react-router-dom"
import axios from 'axios'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './contact.css'
import contact from './contact us.jpg'
// import { Helmet } from 'react-helmet'
const Contact = () => {
    const [state ,setState]=useState({
        Name:"",
        Email:"",
        Message:""
    });
    // const [validated, setValidated] = useState(false);
    const addContact = async(data)=>{
       
       const conn=process.env.REACT_APP_connection;

        const response = await axios.post(conn , data);
        if(response.status ===200 )
        {
            alert("We’re so happy to hear from you! Thank you for your valuable feedback.");
             
        }
      };
      const history =useNavigate();
    const handelInput=(e)=>{
        let name = e.target.id;
        let value = e.target.value;
        console.log(name,value);
        setState({...state, [name]:value});
      };
   
    //   const handleSubmit = (event) => {
    //   const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    //   else{
    //     addContact(state);
       
    //   }
  
    //   setValidated(true);
    // };
    const handelsumit=(e)=>{
        e.preventDefault();
        if (!state.Name || !state.Email || !state.Message )
        {
          // toast.error("Please provide details");
          alert("error")
          } 
        else {
          addContact(state);
           history("/");
          }
      };
  return (
    <div className='session1'>
       {/* <Helmet>
        <title>Nisnaifinserv-Contact us</title>
        </Helmet> */}
   <div className='concontent1'>
      
        <div className='contactform'>
            <form method="post" action="" onSubmit={handelsumit}>
                <h2>Send Message To Us</h2>
                <div className="inputbox">
                    <input type="text" name="Name" id="Name" required  value={state.Name} onChange={handelInput}/>
                    <label htmlFor="Name">Fullname</label>
                </div>
                <div className="inputbox">
                    <input type="email" name="Email" id="Email" required value={state.Email} onChange={handelInput}/>
                    <label htmlFor="Email">Email</label>
                </div>
                <div className="inputbox" >
                   <textarea name="Message"  cols="30" rows="" required id='Message' value={state.Message} onChange={handelInput}></textarea>
                    <label htmlFor="Message">Type Your Message...</label>
                </div>
                <div className="inputbox">
                <input type="submit" value="Submit" />
                </div>
            </form>
        </div> 
        <div className='image1'>
            <p >
            <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d7566.48777887382!2d73.8539188!3d18.5178777!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1714469420738!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/* <Map
        google={this.props.google}
        zoom={14}
        initialCenter={{ lat: YOUR_LATITUDE, lng: YOUR_LONGITUDE }}
      /> */}

            </p>
            <div style={{fontSize:"2em"}}>
            <div>

            <b>Please Click on Below Link for Location</b>
            </div>
            <Link to='https://maps.app.goo.gl/DiHnPvtWUpa7MghP8?g_st=aw'>258 Shreemant Thorle Bajirav Peshve Rd </Link>
            </div>
        </div>     
    </div>
    </div>
  )
}

export default Contact
