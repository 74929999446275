import React from 'react'
import { Button, Carousel,Overlay  } from 'react-bootstrap';
import image1 from '../../Pages/home/image/Capture.JPG';
import image4 from '../../Pages/home/image/Capture1.JPG';
import image5 from '../../Pages/home/image/Capture3.JPG';
import image6 from '../../Pages/home/image/Capture4.JPG';
import image2 from  '../../Pages/home/image/Capture2.JPG';
import image3 from '../../Pages/home/image/slide2.png';
const Slide = () => {
  return (
    <div>
         <div>
      
      <Carousel>
     
     <Carousel.Item>
        <img
          className="d-block w-100"
          src={image1}
          alt="First slide"
        />
      
    
      
      </Carousel.Item>
     <Carousel.Item>
     <img src={image3} alt=""  className="slide"/>
     </Carousel.Item>
     
     <Carousel.Item>
        <img
          className="d-block w-100"
          src={image4}
          alt="First slide"
        />
     </Carousel.Item>
     <Carousel.Item>
        <img
          className="d-block w-100"
          src={image5}
          alt="First slide"
        />
     </Carousel.Item>
     <Carousel.Item>
        <img
          className="d-block w-100"
          src={image6}
          alt="First slide"
        />
     </Carousel.Item>
     <Carousel.Item interval={500}>
     <img src={image2} alt="" height={50} className="slide1" />
     </Carousel.Item>
   </Carousel>
   </div>
    </div>
  )
}

export default Slide