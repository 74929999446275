
import React from 'react'
import './Contenet.css'
import coimg from './img/S40670_550px_X_310px.png'
import coimg1 from './img/SC-F6430_01.jpg'
import coimg2 from './img/4-Leggero-TX_Front_Home_TX_shadow_No.jpg'
import coimg3 from './img/F130-03.jpg'
import { Download } from '@mui/icons-material'

const Sublimation  = () => {
  return (
    <div>
    <div className='cpdiv1'>
         <h1 className='pdh1'>sublimation print</h1>
         <p className='cpp1'>Epson offers a variety of signage printers, like the SureColor series, for businesses needing high-quality prints for signs, banners, and posters. These printers boast advanced technologies for precise printing and support various media types. Epson's SureColor printers are known for their durability and reliability, making them popular choices for indoor and outdoor signage.</p>


   
         <div className="card">

                     <h1 className='header1'>Epson SureColor SC-F6430</h1>
         <div className="cpdiv2rev">

             <div className="cpdiv3 cardp">
                 <img src={coimg1} alt="" />
                 <div className="cpdiv4">
                     <h5>
                     Efficient printing and unrivaled dye sublimation print quality.
                     </h5>
                     <p>

                     The SureColor SC-F6430 is setting fresh benchmarks in textile printers with their reliable and superior print quality. Offering a high-performance solution for small to mid-sized textile production & novelty goods production companies, these 44” digital dye sublimation printers are equipped with an Integrated Ink Pack System and the all-new PrecisionCore MicroTFP printhead.
                     </p>
                     <li> 1118mm (44”) digital dye sublimation textile printer</li>
                     <li>

                     PrecisionCore Micro TFP printhead with 1.33-inch printhead width and nozzle verification technology
                     </li>
                     <li>
                     New Integrated Ink Pack System with 45%1 increase in ink capacity
                     </li>
                     <li>
                     Auto Take-up Reel2 ensures stable and continuous media take-up

                     </li>
                     <li>
                     Improved usability with 4.3” LCD touch panel & 120%3 larger maintenance space

                     </li>
                    
                 </div>
                 {/* <Detail/> */}
             </div>
                 <span className="top"></span>
                 <span className="bottom"></span>
                 <span className="right"></span>
                 <span className="left"></span>
         </div>
         <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/814b25d5d70efe6b87c9d2b30058728b70850c14/original?assetDescr=SureColor-F6430-6430H" className='download'><Download/> Download</a>
         </div>
         <div className="card">

                     <h1 className='header1'>Epson SureColor SC-F6430H</h1>
         <div className="cpdiv2rev">

             <div className="cpdiv3 cardp">
                 <img src={coimg1} alt="" />
                 <div className="cpdiv4">
                     <h5>
                     Efficient printing and unrivalled textile print quality, now with orange and violet inks.
                     </h5>
                     <p>

                     The SureColor SC-F6430H is setting fresh benchmarks in textile printers with their reliable and superior print quality. Offering a high-performance solution for small to mid-sized textile production and novelty goods production companies, these 44” digital dye sublimation printers are equipped with an Integrated Ink Pack System and the all-new PrecisionCore MicroTFP printhead.                     </p>
                     <li>44” digital dye sublimation textile printer</li>
                     <li>

                     PrecisionCore Micro TFP printhead with 1.33-inch printhead width and nozzle verification technology                     </li>
                     <li>
                     New Integrated Ink Pack System with 45%1 increase in ink capacity
                     </li>
                     <li>
                     Auto Take-up Reel2 ensures stable and continuous media take-up

                     </li>
                  
                 </div>
                 {/* <Detail/> */}
             </div>
                 <span className="top"></span>
                 <span className="bottom"></span>
                 <span className="right"></span>
                 <span className="left"></span>
         </div>
         <a href="https://download.epson.com.sg/product_brochures/large_format/ESD/TextileRangeBrochure.pdf" className='download'><Download/> Download</a>
         </div>
         <div className="card">

                     <h1 className='header1'>Epson SureColor SC-F530 Desktop Dye-Sublimation Textile Printer</h1>
         <div className="cpdiv2rev">

             <div className="cpdiv3 cardp">
                 <img src={coimg2} alt="" />
                 <div className="cpdiv4">
                     <h5>
                     DESIGNED FOR PERFORMANCE, PURPOSED FOR AFFORDABILITY.
                     </h5>
                     <p>

                     Meet the creative demands of your customers without the added time and cost to your business with the 61.0cm (24-inch) Epson SC-F530 dye-sublimation printer. Built and designed to provide the user with ease of use, greater productivity and exceptional efficiency, the SC-F530 encompasses the convenient Epson Total Solutions, LFP Accounting Tool, and a printer design that requires little time to operate and maintain. Economically ideal for small businesses with a lower print volume.                         </p>

                     <li>61.0cm (24-inch) Dye-Sublimation Printer</li>
                     <li>

                     CISS ink tank with CYMK ink 
                     </li>
                     <li>
                     High-performance dye-sublimation transfer printer 
                     </li>
                     <li>
                     Ideal for small-volume clothing and custom merchandise 

                     </li>
                    
                 </div>
                 {/* <Detail/> */}
             </div>
                 <span className="top"></span>
                 <span className="bottom"></span>
                 <span className="right"></span>
                 <span className="left"></span>
         </div>
         <a href="http://mediaserver.goepson.com/ImConvServlet/imconv/f9ae90bc674309c0c9e77364363eda64a777996a/original?assetDescr=Epson%20F530" className='download'><Download/> Download</a>
         </div>
         <div className="card">

                     <h1 className='header1'>Epson SureColor SC-F130 A4 Dye-Sublimation Textile Printer</h1>
         <div className="cpdiv2rev">

             <div className="cpdiv3 cardp">
                 <img src={coimg3} alt="" />
                 <div className="cpdiv4">
                     <h5>
                     GENUINE TOTAL SOLUTION WITH STUNNING PRINT QUALITY.
                     </h5>
                     <p>

                     The first of Epson’s genuine A4 dye-sublimation printer solution, the SC-F130 is the ideal business solution or those businesses craving for a high quality, short-run, yet low cost entry to the novelty gifts industry. Epson provides a total solution for the SC-F130, giving customers a complete peace-of-mind with the Epson Genuine UltraChrome DS inks, transfer paper *1 , printer driver and a 1-year genuine warranty. The printer is perfect for producing small and bespoke products such as cushion covers, bags, mugs, and even aluminium photo frames without additional fuss.</p>
                     <li>A4 Dye-Sublimation Printer</li>
                     <li>

                     CISS ink tank with CYMK ink 
                     </li>
                     <li>
                     High-performance dye-sublimation transfer printer 
                     </li>
                     <li>
                     Ideal for small-volume clothing and custom merchandise 

                     </li>
                    
                 </div>
                 {/* <Detail/> */}
             </div>
                 <span className="top"></span>
                 <span className="bottom"></span>
                 <span className="right"></span>
                 <span className="left"></span>
         </div>
         <a href="https://mediaserver.goepson.com/ImConvServlet/imconv/287d527e5db1fa287573dae36ab95ba3f30f1dcb/original?assetDescr=Epson%20F130" className='download'><Download/> Download</a>
         </div>

     </div>
 </div>
  )
}

export default Sublimation 
