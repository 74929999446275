import React from 'react';
import img1 from '../home/image/ketan.jpg';
import img2 from './kindpng_197342.png';
import img3 from './pngegg.png';
import img4 from '../home/image/happycust.jpg';
import img5 from '../home/image/img2.jpg';
import img6 from '../home/image/img3.jpg';
import './About.css';
const Adboutus = () => {
  return (
    <div>
        <h1 className="abh1">About Us</h1>
        <div className="abdiv1">
          
        <img src={img1} alt="" />
        <p className="abp">
         
Since 2019, Image Solution has been a trusted name in printing gear. We work closely with big names like Epson and Xerox, serving over +200 happy clients in Pune and Rest of Maharashtra. Our gear is not just affordable; it's top-notch quality, designed to keep you satisfied for the long haul. We're all about making printing easy and automatic for you in the future.

        <p >
         
        In today's busy business world, our printing solutions do more than just help you get things done. They make your life easier, helping you shine and get ahead.

        </p>
        <p >
         
        We're here for you. At Image Solution, we're all about delivering top-notch service at great prices. Our team works hard to make sure you have everything you need, making printing simpler and more organized for you and your business.
        </p>
        </p>
        </div>
        <div className="abvision">
          <div className="abdiv2">
            <div className="abdiv4">
            <div className="adimgdiv">
                <img src={img2} alt="" />
            </div>  
          <h1 className="abh1">Vision</h1>
            <p>"By continuously striving to exceed customer expectations and embracing innovative ideas and products, we pave the path to success."</p>
            </div>
            <div className="abdiv5">
            <div className="adimg1div">
                <img src={img3} alt="" />
            </div>  
          <h1 className="abh1">Mission</h1>
            <p>"Our aim is to lead the printing and packaging industry, setting the standard for excellence in specialty papers, films, and other substrates."</p>
            </div>
        </div>
          </div>
        <div className="divcust">
            <h1> + 200 Happy Customer</h1>
            <div className="divcust1">
              <div className="custimg1">
                <img src={img4} alt="" />
             
            </div>
          </div>
        </div>
    </div>
  )
}

export default Adboutus