import React from 'react'

import logo from '../Pages/home/image/logo.png'
import './header.css'
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
const Header = () => {
  
  return (
    
    <div>
        <Navbar collapseOnSelect expand="lg" className="custom-navbar">
      <Container className='con'>
        <Navbar.Brand href="#home">
        <img src={logo} alt="" className='logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          
          </Nav>
          <Nav>
          <Nav.Link>
            <Link to={'/'} className='a'>Home</Link>
            
          
          </Nav.Link>
        
            
            <NavDropdown title="Epson" id="collapsible-nav-dropdown" className='a4' >
              
              <NavDropdown.Item >
              <Link to={'/WorkForce'} className='a1'>Business Inkjet Printers</Link>
                  
              </NavDropdown.Item>
              <NavDropdown.Item >
              <Link to={'/SureColour'} className='a1'>Photo Printers</Link>
                
              </NavDropdown.Item>
              <NavDropdown.Item >
              <Link to={'/MonoChromeEcoTank'} className='a1'>InkTank Printers</Link>
            
              </NavDropdown.Item>
              <NavDropdown.Item >
              <Link to={'/signageprinter'} className='a1'>Signage Printer</Link>
            
              </NavDropdown.Item>
              <NavDropdown.Item >
              <Link to={'/Sublimation'} className='a1'>Sublimation Printer</Link>
            
              </NavDropdown.Item>
              <NavDropdown.Item >
              <Link to={'/Cad'} className='a1'>CAD Printer</Link>
            
              </NavDropdown.Item>
             
            </NavDropdown>
            
            <NavDropdown title="Xerox" id="collapsible-nav-dropdown" className='a2'>
              
              <NavDropdown.Item >
              <Link to={'/Cutsheet'} className='a1'>Cutsheet</Link>
                  
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              <Link to={'/Monocut'} className='a1'>Monocut</Link>
                
              </NavDropdown.Item>
              
             
            </NavDropdown>
            <Nav.Link>

            <Link to={'/about-us'} className='a'>About Us</Link>
            </Nav.Link>
            <Nav.Link>

            <Link to={'/Contact'} className='a'>Contact Us</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
   
    </div>
  )
}

export default Header