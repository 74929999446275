import React from 'react'
import Slide from '../../Component/Home/Slide'
import Trust from '../../Component/Home/Trust'
import Welcome from '../../Component/Home/Welcome'
import Patner from '../../Component/Home/Patner'
import img1 from './image/IMG-20240506-WA0003-removebg-preview.png'
import img2 from './image/IMG20240507142313.jpg'
import img3 from './image/IMG20240507142255.jpg'
import './home.css'


function Home() {
  return (
    <div>
      
       <Slide/>

       <Trust/>
       <div>

       <Welcome/>
       </div>
       <div className="certimg">
          <h1>Certificate & Achievement</h1>
          <div className="certimg1">

          <img src={img2} alt=""  className='cerimg1'/>
          <img src={img1} alt="" className='cerimg2'/>
          <img src={img3} alt=""className='cerimg1' />
          </div>
       </div>
       <Patner/>
    </div>
  )
}

export default Home
