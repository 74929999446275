import React from 'react'
import "./header.css"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import img from '../Pages/home/image/logo.png'
const Footer = () => {
    return (
        <div className="footerdiv1">


            <div className="footerdiv">

                <div className='footaddre'>
                    {/* <img src={img} alt="" /> */}
                    <h1>Address</h1>
                    <p>
                        Basera Hotel, Shaniwar Peth, Pune - 411030 India, Maharashtra
                    </p>
                </div>
                <div className='footaddre'>
                    <img src={img} alt="" />


                </div>

                <div className='footall'>
                    <a className="footerp" href="mailto:Ketan@imagesolution.org" ><EmailIcon />Ketan@imagesolution.org<br /> </a>

                    <a href="tel:9011776866" className="footerp"><PhoneIcon /> +919011776866</a><br/>
                    <a className="footerp" href='https://www.facebook.com/profile.php?id=61551581737963'>< FacebookIcon />Image Solutions <br /> </a>

                    <a className="footerp" href='https://www.instagram.com/images_solutions?igsh=MWN2cDhvZmVvNWdzYg%3D%3D&utm_source=qr '><InstagramIcon /> @images_solutions </a>
                </div>





            </div>



        </div>
    )
}

export default Footer
