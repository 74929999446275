import React from 'react'
import '../Contenet.css'
import xer from '../img/optimise (6).webp'
import xer1 from '../img/optimise (7).webp'
import xer2 from '../img/optimise (8).webp'
import { Download } from '@mui/icons-material'


const Monocut = () => {
  return (
    <div className='cpdiv1'>
    <h1  className='pdh1'>Mono Cut Sheet</h1>
 
    <div className="card">

        <h1 className='header1'>Xerox Nuvera® 120/144/157 Presses</h1>
    <div className="cpdiv2">
      <div className="cpdiv3 cardp">
        <img src={xer} alt="" />
        <div className="cpdiv4">
          <h5>
          Stunning image quality
          </h5>
          
          <ul>
            <li>15 different ways to finish output inline or near-line</li>
            <li>44 gsm to 300 gsm</li>
            <li>Up to 23,200 sheet feed capacity, load while run</li>
           
          </ul>
        </div>
      </div>
        <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
    </div>
    <a href="https://www.xerox.com/digital-printing/latest/NU2BR-04U.pdf" className='download'><Download/> Download</a>
    </div>
    <div className="card">

        <h1 className='header1'>Xerox Nuvera® 288/314 Presses</h1>
    <div className="cpdiv2">
      <div className="cpdiv3 cardp">
        <img src={xer1} alt="" />
        <div className="cpdiv4">
          <h5>
          The formula for perfect productivity
          </h5>
          
          <ul>
            <li>15 different ways to finish output inline or near-line</li>
            <li>44 gsm to 300 gsm </li>
            <li>Up to 23,200 sheet feed capacity, load while run</li>
           


          </ul>
        </div>
      </div>
        <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
    </div>
    <a href="https://www.office.xerox.com/latest/NU2BR-01U.PDF" className='download'><Download/> Download</a>
    </div>
    <div className="card">

        <h1 className='header1'>Xerox® PrimeLink® B9100 Series</h1>
    <div className="cpdiv2">
      <div className="cpdiv3 cardp">
        <img src={xer2} alt="" />
        <div className="cpdiv4">
          <h5>
          Production ready, office-friendly black and white.
          </h5>
          
          <ul>
            <li>B&W MFP with support for 13 x 26 in. (330 x 660mm) banner printing</li>
            <li>Walk-up simplicity and production-level capability in one compact device</li>
            <li>Incredibly reliable with exceptional image quality</li>
            <li>Endlessly configurable – feeding, finishing, and Digital Front End options to fit every print environment</li>
            <li>A wealth of production-friendly, office-ready features including workflow apps and built-in security</li>


          </ul>
        </div>
      </div>
        <span className="top"></span>
        <span className="bottom"></span>
        <span className="right"></span>
        <span className="left"></span>
    </div>
    <a href="https://www.office.xerox.com/latest/PB9BR-01U.PDF" className='download'><Download/> Download</a>
    </div>



   







  </div>
  )
}

export default Monocut
