import React from 'react'
import '../Contenet.css'
import xer from '../img/optimise.webp'
import xer1 from '../img/optimise (1).webp'
import xer2 from '../img/optimise (2).webp'
import xer3 from '../img/optimise (3).webp'
import xer4 from '../img/optimise (4).webp'
import xer5 from '../img/optimise (5).webp'
import { Download } from '@mui/icons-material'

const Cutsheet = () => {
  return (
    <div className='cpdiv1'>
      <h1  className='pdh1'>Color Cut Sheet</h1>
   


    <div className="card">

          <h1 className='header1'>Xerox® Iridesse® Production Press</h1>
      <div className="cpdiv2">
        <div className="cpdiv3 cardp">
          <img src={xer} alt="" />
          <div className="cpdiv4">
            <h5>
              Powers a more brilliant future by delivering more “wow” impact per printed page through digital specialty enhancements.
            </h5>
           
            <ul>
              <li>Up to six inline print stations that print in a single pass, at rated speed, with spot on color-to-color registration</li>
              <li>Optional White, Clear, Silver, Gold, and Fluorescent Pink Specialty Dry Inks for enhancements and Media Expansion Low Gloss Clear</li>
              <li>Full Width Array, a powerful Xerox-exclusive technology that baselines the press to expedite time to production</li>
              <li>Optimal performance with mixed-stock jobs supported by Mixed Media Xceleration (MMX)</li>
              <li>Additional high capacity feeders and inline finishing solutions—finish landscape booklets inline using extra-long sheets (XLS)</li>

            </ul>
          </div>
        </div>
          <span className="top"></span>
          <span className="bottom"></span>
          <span className="right"></span>
          <span className="left"></span>
      </div>
      <a href="https://www.xerox.com/digital-printing/latest/XIPDS-01U.pdf" className='download'><Download/> Download</a>
    </div>
    <div className="card">

          <h1 className='header1'>Xerox® Versant® 280 Press</h1>
      <div className="cpdiv2">
        <div className="cpdiv3 cardp">
          <img src={xer1} alt="" />
          <div className="cpdiv4">
            <h5>
            The all-in-one, ever-evolving, field-proven profit builder. Reimagined to deliver everything you need in a mid-production press.
            </h5>
           
            <ul>
              <li>All Stocks Rated Speed (ASRS) option to print 25% faster on every stock up to 400 gsm</li>
              <li>Take automation to the extreme with click-simple automated color quality with an inline Spectrophotometer and ASRS speed with the Performance Package (PP) option </li>
              <li>Bigger is better with production printing of up to 47” / 1.2m sheet in Ultra HD</li>
              <li>Get noticed with the Xerox® Adaptive CMYK+ Kit: Vivid and Fluorescent Specialty Toners add gold, silver, white, clear, or fluorescent cyan, fluorescent magenta and fluorescent yellow to your applications</li>


            </ul>
          </div>
        </div>
          <span className="top"></span>
          <span className="bottom"></span>
          <span className="right"></span>
          <span className="left"></span>
      </div>
      <a href="https://www.office.xerox.com/latest/V28BR-01U.PDF" className='download'><Download/> Download</a>
    </div>
    <div className="card">

          <h1 className='header1'>Xerox® Versant® 4100 Press</h1>
      <div className="cpdiv2">
        <div className="cpdiv3 cardp">
          <img src={xer2} alt="" />
          <div className="cpdiv4">
            <h5>
            A versatile press reinvented with even more smart automation for virtually touch-free quality control
            </h5>
           
            <ul>
              <li>Simplify and automate time-consuming tasks like image-to-media alignment, density, uniformity, color calibration and profiling with our Full Width Array (FWA)</li>
              <li>Print high-quality output with Xerox® EA Toner for incredible 2400 x 2400 dpi Ultra HD Resolution and 10-bit RIP rendering</li>
              <li>Versatile feeding up to 47” / 1.2 m sheets and Finishing options for creating exactly the Versant press you need to build your business</li>
              <li>Xerox® PredictPrint Media Manager provides powerful, cloud-based stock management</li>


            </ul>
          </div>
        </div>
          <span className="top"></span>
          <span className="bottom"></span>
          <span className="right"></span>
          <span className="left"></span>
      </div>
      <a href="https://www.office.xerox.com/latest/V41BR-01U.PDF" className='download'><Download/> Download</a>
    </div>


  
    <div className="card">

          <h1 className='header1'>Xerox® PrimeLink® C9065/C9070 Printer</h1>
      <div className="cpdiv2">
        <div className="cpdiv3 cardp">
          <img src={xer5} alt="" />
          <div className="cpdiv4">
            <h5>
            Breakthrough performance. New business results.
            </h5>
           
            <ul>
              <li>Color MFP with support for 13 x 26 in. (330 x 660mm) banner printing</li>
              <li>Flexible, production-ready finishing and feeding with a range of Digital Front Ends to fit every print environment</li>
              <li>Exceptional reliability and image quality with built-in security and workflow apps</li>
              <li>Get noticed with Xerox® CMYK Plus Technology: Vivid and Fluorescent Specialty Toners add gold, silver, white, clear, or fluorescent cyan, fluorescent magenta and fluorescent yellow to your applications</li>


            </ul>
          </div>
        </div>
          <span className="top"></span>
          <span className="bottom"></span>
          <span className="right"></span>
          <span className="left"></span>
      </div>
      <a href="https://www.office.xerox.com/latest/PC9BR-01U.PDF" className='download'><Download/> Download</a>
    </div>









    </div>
  )
}

export default Cutsheet
